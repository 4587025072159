import { css } from 'astroturf';
import React from 'react';
import CTA from '../../components/CTA';
import DocHeader from '../../components/DocHeader';
import DocsListing from '../../components/DocsListing';
import SEO from '../../components/SEO';
import DocSupport from '../../components/docs/support';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .docContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .menu {
    position: sticky;
    top: 70px;
    padding-top: 10px;
    z-index: 9;

    & > ul {
      display: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: #fff;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      position: relative;
      top: 0;
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      background: #fff;
      display: block;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }
  }

  .content {
    flex: 2;

    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }
  }
  .dirNav {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    & svg {
      vertical-align: text-bottom;
      transform: translateY(-2px);
    }
  }
`;

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');

    return (
      <Layout>
        <SEO title="Documentation" keywords={['']} pathname={this.props.location.pathname} />
        <DocHeader />

        <div className="widePage">
          <div className={styles.docContainer}>
            <div className={menuClasses}>
              <button type="button" onClick={this.showMenu}>
                {this.state.menuShown ? 'Hide menu' : 'Show menu'}
              </button>
              <DocsListing />
            </div>

            <section className={styles.content}>
              <div className="childPadding">
                <h1>Polypane Documentation</h1>
                <p>
                  Polypane is a browser for developers and designers with tons of features that help you build websites
                  that are responsive, accessible and of high quality. This Quick Start guide aims to help you become
                  familiar with Polypane's basic features.
                </p>
                <h2>Quick Start</h2>
                <p>
                  The best way to begin with Polypane is to follow the Quick Start guide available within the
                  application. This hands-on tutorial walks you through the common actions in Polypane and learn the UI.
                </p>
                <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                  <iframe
                    loading="lazy"
                    title="getting started overview"
                    src="https://www.youtube.com/embed/tdOKznrXuow?&amp;autoplay=false&amp;start=0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen=""
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0"
                  />
                </div>
                <h2>Key features</h2>
                <ul>
                  <li>
                    <strong>Multi-Viewport Display</strong>: Visualize your website across multiple viewports
                    simultaneously, referred to as "<Link to="/docs/pane-management/">panes</Link>" in Polypane.
                  </li>
                  <li>
                    <strong>Easy Pane Management</strong>: Easily <Link to="/docs/pane-management/">manage panes</Link>{' '}
                    by adding, removing, and resizing them according to your needs. Customize their behavior based on{' '}
                    <Link to="/docs/breakpoints/">CSS breakpoints</Link>, device presets, or create{' '}
                    <Link to="/docs/pane-sizes/">completely freeform panes</Link>.
                  </li>
                  <li>
                    <strong>
                      Synced <Link to="/docs/synced-interactions/">Interactions</Link>
                    </strong>
                    : Seamlessly interact with all viewports, ensuring synchronized scrolling, hovering, and form input
                    for efficient testing.
                  </li>
                  <li>
                    <strong>
                      Versatile <Link to="/docs/layouts/">layouts</Link> Options
                    </strong>
                    : Choose from horizontal, vertical, or focus layouts to tailor your workspace. Switch to the Full
                    layout for a more traditional browsing experience.
                  </li>
                  <li>
                    <strong>Enhanced Testing Tools</strong>: Access a variety of tools, including{' '}
                    <Link to="/docs/reference-image/">image overlay</Link> and{' '}
                    <Link to="/docs/emulation/">media query emulation</Link>, to streamline your testing process.
                  </li>
                </ul>
                <h2>Exploring Polypane</h2>
                <p>Delve deeper into Polypane's features to uncover its full potential:</p>
                <ul>
                  <li>
                    <strong>
                      <Link to="/docs/intro-to-panes/">Panes</Link>
                    </strong>
                    : Explore all the functions of panes, like <Link to="/docs/emulation/">emulation</Link>,{' '}
                    <Link to="/docs/debug-tools/">debug tools</Link>,{' '}
                    <Link to="/docs/reference-image/">image overlay</Link> and more.
                  </li>

                  <li>
                    <strong>Devtools</strong>: A wide variety of devtools are available in Polypane, like{' '}
                    <Link to="/docs/layout-debugging/">Layout debugging</Link>, many ways to make and edit{' '}
                    <Link to="/docs/making-screenshots/">screenshots</Link> of different parts of your page, a quick{' '}
                    <Link to="/docs/color-picker/">color picker</Link> and more.
                  </li>

                  <li>
                    <strong>
                      <Link to="/docs/intro-to-panel/">Panels</Link>
                    </strong>
                    : Utilize various panels within Polypane, such as the{' '}
                    <Link to="/docs/elements-panel/">Elements panel</Link>,{' '}
                    <Link to="/docs/meta-information/">Meta panel</Link> and{' '}
                    <Link to="/docs/outline-panel/">Outline panel</Link> for better insights into your web projects.
                  </li>
                  <li>
                    <strong>
                      <Link to="/docs/portal/">Portal</Link>
                    </strong>
                    : Invite other browsers to the party and test and share your work across different devices and
                    browsers.
                  </li>
                </ul>
                <h2>Switching to Polypane</h2>
                <p>
                  Switching to a new development tool can take some getting used to. We have a few pointers on how to
                  make the <Link to="/docs/switching-to-polypane/">switch to Polypane</Link> easier.
                </p>

                <p>
                  Beyond that, familiarize yourself with Polypane's{' '}
                  <Link to="/docs/ui-overview/">user interface and navigation</Link> and browse through the extensive
                  documentation to find the features you need.{' '}
                </p>
                <h2>Need help? Chat with us in the app</h2>
                <p>
                  If you encounter any challenges or have questions while using Polypane, our support team is here to
                  assist you.
                </p>

                <p>
                  During the trial period click on "Onboarding support" to open a live chat with us. This is available
                  for everyone under the "Chat with us" option in the Help menu.
                </p>
              </div>
              <DocSupport />
            </section>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Index;
